@font-face {
  font-family: 'Effra';
  src: url('/assets/fonts/Effra-Bold.woff2') format('woff2'),
      url('/assets/fonts/Effra-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Effra';
  src: url('/assets/fonts/Effra-Light.woff2') format('woff2'),
      url('/assets/fonts/Effra-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Effra';
  src: url('/assets/fonts/Effra-Medium.woff2') format('woff2'),
      url('/assets/fonts/Effra-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Effra';
  src: url('/assets/fonts/Effra-Regular.woff2') format('woff2'),
      url('/assets/fonts/Effra-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

